import axios from "./axios";

export async function getTest(test_id) {
    const response = await axios.get(`/tests/${test_id}`);
    return response.data;
}

export async function getAllQuestions(test_id) {
    const test = await axios.get(`/tests/${test_id}`);
    let questions = [];
    for (let index = 0; index < test.data.questions.length; index += 1) {
        const question = test.data.questions[index];
        console.log("question", question)
        const question_response = await axios.get(`/questions/${question}`);
        questions.push(question_response.data)
    }
    test.data.questions = questions;
    return test.data;
}

export async function createAnswer({user_id, question_id, answer}) {
    const body = {
        user_id, 
        question_id, 
        answer
    }
    const response = await axios.post(`/answers`, body);
    return response.data;
}