import React, {useContext} from "react";
import { UserContext } from "../../providers/userProvider";
import Footer from "components/Footer";
import MenuStart from "components/MenuStart";
import { Button, Img, List, Text } from "components";

const FraccionesPage = () => {
  const { user } = useContext(UserContext);

  return (
    <>
    {user && (
      <div className="bg-white_A700 flex flex-col font-inter items-center justify-start mx-auto py-[38px] w-full">
        <div className="flex flex-col items-center justify-start mb-1 w-full">
          <MenuStart
            className="flex flex-row items-center ml-[10px] mr-[10px] w-full"
          />
          <Img
            className="h-[127px] mt-[125px]"
            src="images/img_matematica.svg"
            alt="matematica"
          />
          <Img
            className="h-[54px] mt-[18px]"
            src="images/img_group_red_400.svg"
            alt="group"
          />
          <div className="flex flex-col justify-start mt-[124px] w-full">
            <div className="bg-deep_orange_A200 flex flex-col items-center justify-end p-[71px] md:px-10 sm:px-5 w-full">
              <div className="flex flex-col items-center justify-start mt-[7px]">
                <Text
                  className="md:text-5xl text-[70px] text-center text-white_A700"
                  as="h3"
                  variant="h3"
                >
                  Fracciones
                </Text>
                <Text
                  className="md:text-5xl text-[70px] text-center text-white_A700"
                  as="h6"
                  variant="h6"
                >
                  Hemos clasificado los contenidos según tu perfil de aprendizaje
                </Text>
              </div>
            </div>
            <div className="flex md:flex-col flex-row md:gap-5 items-center justify-start mt-32 md:px-5 w-[93%] md:w-full">
              <Text
                className="ml-5 md:ml-[0] ml-[85px] text-deep_orange_A200"
                as="h3"
                variant="h3"
              >
                1.{""}Comprender
              </Text>
            </div>
            <List
                className="sm:flex-col flex-row gap-[29px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 justify-center ml-1 md:ml-[0] mt-[39px] w-full"
                orientation="horizontal"
            >
                <div className="md:h-[148px] h-[232px] relative w-full">
                <div className="absolute border-[6px] border-deep_orange_A200 border-solid md:h-[148px] h-[202px] inset-x-[0] mx-auto pb-[31px] sm:px-5 px-[31px] rounded-[60px] top-[0] w-full">
                    <Text
                    className="absolute leading-[116.70%] right-[5%] sm:text-4xl md:text-[38px] text-[40px] text-black-900 mt-[30px] w-[62%] sm:w-full"
                    size="txtInterMedium40"
                    >
                    Fracciones desde cero
                    </Text>
                    <Img
                    className="absolute h-24 left-[5%] object-cover top-[14%] w-24"
                    src="images/img_image1014.png"
                    alt="image1014"
                    />
                </div>
                <Button onClick={() => window.open('https://www.youtube.com/watch?v=5U2ei-Cl0pc', "_blank", "noreferrer")}className="absolute bg-deep_orange_A200 bottom-[0] cursor-pointer font-black min-w-[292px] py-[11px] right-[5%] rounded-[33px] sm:text-[31px] md:text-[33px] text-[35px] text-center text-white_A700">
                    VER VIDEO
                </Button>
                </div>
                <div className="md:h-[148px] h-[232px] relative w-full">
                <div className="absolute border-[6px] border-deep_orange_A200 border-solid md:h-[148px] h-[202px] inset-x-[0] mx-auto pb-[31px] sm:px-5 px-[31px] rounded-[60px] top-[0] w-full">
                    <Text
                    className="absolute leading-[116.70%] right-[5%] sm:text-4xl md:text-[38px] text-[40px] text-black-900 mt-[30px] w-[62%] sm:w-full"
                    size="txtInterMedium40"
                    >
                    Fracciones equivalentes
                    </Text>
                    <Img
                    className="absolute h-24 left-[5%] object-cover top-[14%] w-24"
                    src="images/img_image1014.png"
                    alt="image1015"
                    />
                </div>
                <Button onClick={() => window.open('https://www.youtube.com/watch?v=5U2ei-Cl0pc', "_blank", "noreferrer")} className="absolute bg-deep_orange_A200 bottom-[0] cursor-pointer font-black min-w-[292px] py-[11px] right-[5%] rounded-[33px] sm:text-[31px] md:text-[33px] text-[35px] text-center text-white_A700">
                    VER VIDEO
                </Button>
                </div>
                <div className="md:h-[148px] h-[232px] relative w-full">
                <div className="absolute border-[6px] border-deep_orange_A200 border-solid md:h-[148px] h-[202px] inset-x-[0] mx-auto pb-[31px] sm:px-5 px-[31px] rounded-[60px] top-[0] w-full">
                    <Text
                    className="absolute leading-[116.70%] right-[5%] sm:text-4xl md:text-[38px] text-[40px] text-black-900 mt-[30px] w-[62%] sm:w-full"
                    size="txtInterMedium40"
                    >
                    Suma y resta de Fracciones{" "}
                    </Text>
                    <Img
                    className="absolute h-24 left-[5%] object-cover top-[14%] w-24"
                    src="images/img_image1014.png"
                    alt="image1016"
                    />
                </div>
                <Button onClick={() => window.open('https://www.youtube.com/watch?v=5U2ei-Cl0pc', "_blank", "noreferrer")} className="absolute bg-deep_orange_A200 bottom-[0] cursor-pointer font-black min-w-[292px] py-[11px] right-[5%] rounded-[33px] sm:text-[31px] md:text-[33px] text-[35px] text-center text-white_A700">
                    VER VIDEO
                </Button>
                </div>
            </List>
            <div className="flex md:flex-col flex-row md:gap-5 items-start justify-start mt-[102px] md:px-5 w-[93%] md:w-full">
                <Text
                    className="ml-5 md:ml-[0] ml-[85px] text-deep_orange_A200"
                    as="h3"
                    variant="h3"
                >
                    2.{""}Retos
                </Text>
              <div className="flex md:h-[57px] h-[58px] justify-end md:ml-[0] ml-[1105px] md:mt-0 mt-[10px] relative w-[13%] md:w-full">
                <div className="absolute bg-deep_orange_A200_0c flex flex-col h-full inset-[0] items-start justify-center m-auto p-2.5 rounded-[29px] w-full">
                  <Img
                    className="h-[37px] md:ml-[0] ml-[34px] w-9"
                    src="images/img_group14693.svg"
                    alt="group14693"
                  />
                </div>
              </div>
            </div>
            <div className="gap-[29px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 items-center justify-between ml-1 md:ml-[0] w-full">
                <div className="flex flex-col relative w-full">
                    <div className=" md:h-[251px] h-[435px] mx-auto w-full">
                        <div className="flex flex-col gap-[13px] h-full items-center justify-start mt-[15px] mx-auto w-[92%]">
                            <Text
                            className="leading-[116.70%] sm:text-[31px] md:text-[33px] text-[35px] text-black-900 mt-[20px] w-[93%] sm:w-full"
                            size="txtInterMedium35"
                            >
                            Ejercicio comprender fracciones
                            </Text>
                            <Img
                            className="absolute bottom-[15%] h-[234px] md:h-auto object-cover w-full"
                            src="images/img_capturadepantalla.png"
                            alt="capturadepantal"
                            />
                        </div>
                        <div className="absolute bg-deep_orange_A200_7f border-[6px] border-deep_orange_A200 border-solid flex flex-col h-full inset-[0] items-center justify-center m-auto py-[49px] rounded-[60px] w-full">
                            <div className="bg-deep_orange_A200 flex sm:flex-col flex-row gap-1.5 items-center justify-start mt-[268px] p-0.5 w-full">
                            <Text
                                className="sm:ml-[0] ml-[33px] text-3xl sm:text-[26px] md:text-[18px] text-center text-white_A700"
                                size="txtInterBlack30WhiteA700"
                            >
                                PRACTICA COMPLETA
                            </Text>
                            <Img
                                className="sm:flex-1 h-[59px] md:h-auto mb-1.5 object-cover w-[13%] sm:w-full"
                                src="images/img_image1017.png"
                                alt="image1017"
                            />
                            </div>
                        </div>
                        <Text
                            className="absolute bg-deep_orange_A200 h-[66px] justify-center ml-auto left-[30%] bottom-[-7%] pb-[9px] pt-[13px] sm:px-5 px-[35px] rounded-[33px] sm:text-[31px] md:text-[33px] text-[35px] text-center text-white_A700 w-[292px]"
                            size="txtInterBlack35"
                            >
                            REPETIR
                        </Text>
                    </div>
                </div>
                <div className="flex flex-col relative pt-[30px] w-full">
                    <div className="border-[6px] border-deep_orange_A200 border-solid md:h-[151px] h-[435px] mx-auto p-[15px] rounded-[60px] w-full">
                        <Text
                            className="absolute inset-x-[0] leading-[116.70%] mx-auto sm:text-[31px] md:text-[33px] text-[35px] text-black-900 top-[15%] w-[88%] sm:w-full"
                            size="txtInterMedium35"
                        >
                            Ejercicio comparar fracciones
                        </Text>
                        <Img
                            className="absolute bottom-[15%] h-[251px] left-[6%] object-cover w-[82%]"
                            src="images/img_capturadepantalla_251x448.png"
                            alt="capturadepantal_One"
                        />
                    </div>
                        <Text
                        className="bg-deep_orange_A200 h-[66px] justify-center ml-auto mr-[27px] mt-[-33px] pb-[9px] pt-[13px] sm:px-5 px-[35px] rounded-[33px] sm:text-[31px] md:text-[33px] text-[35px] text-center text-white_A700 w-[292px] z-[1]"
                        size="txtInterBlack35"
                        >
                            INICIAR
                        </Text>
                </div>
                <div className="flex flex-col relative pt-[30px] w-full">
                    <div className="border-[6px] border-deep_orange_A200 border-solid md:h-[151px] h-[435px] mx-auto p-[15px] rounded-[60px] w-full">
                        <Text
                            className="absolute inset-x-[0] leading-[116.70%] mx-auto sm:text-[31px] md:text-[33px] text-[35px] text-black-900 top-[15%] w-[88%] sm:w-full"
                            size="txtInterMedium35"
                        >
                            Ejercicio ordenar fracciones
                        </Text>
                        <Img
                            className="absolute bottom-[15%] h-[251px] left-[6%] object-cover w-[82%]"
                            src="images/img_capturadepantalla_260x461.png"
                            alt="capturadepantal_One"
                        />
                    </div>
                        <Text
                        className="bg-deep_orange_A200 h-[66px] justify-center ml-auto mr-[27px] mt-[-33px] pb-[9px] pt-[13px] sm:px-5 px-[35px] rounded-[33px] sm:text-[31px] md:text-[33px] text-[35px] text-center text-white_A700 w-[292px] z-[1]"
                        size="txtInterBlack35"
                        >
                            INICIAR
                        </Text>
                </div>
                </div>
                <Text
                    className="ml-5 md:ml-[0] ml-[85px] mt-[55px] text-deep_orange_A200"
                    as="h3"
                    variant="h3"
                >
                    3.{""}Problemas
                </Text>
                <div className="gap-[29px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 items-center justify-between ml-1 md:ml-[0] mt-[35px] w-full">
                    <div className="flex flex-col relative mt-[20px] w-full">
                        <div className="border-[6px] border-deep_orange_A200 border-solid md:h-[249px] h-[435px] mx-auto p-[15px] rounded-[60px] w-full">
                            <Text
                                className="absolute leading-[116.70%] left-[7%] sm:text-[31px] md:text-[33px] text-[35px] text-black-900 mt-[20px] w-[73%] sm:w-full"
                                size="txtInterMedium35"
                            >
                                Resuelve el problema con fracciones
                            </Text>
                            <Img
                                className="absolute bottom-[15%] h-[249px] left-[8%] object-cover w-[79%]"
                                src="images/img_capturadepantalla_249x432.png"
                                alt="capturadepantal_Three"
                            />
                        </div>
                        <Text
                            className="bg-deep_orange_A200 h-[66px] justify-center ml-auto mr-[27px] mt-[-33px] pb-[9px] pt-[13px] sm:px-5 px-[35px] rounded-[33px] sm:text-[31px] md:text-[33px] text-[35px] text-center text-white_A700 w-[292px] z-[1]"
                            size="txtInterBlack35"
                        >
                            INICIAR
                        </Text>
                    </div>
                    <div className="flex flex-col relative mt-[25px] w-full">
                        <div className="border-[6px] border-deep_orange_A200 md:h-[249px] h-[435px] border-solid flex flex-col gap-[22px] items-center justify-start mx-auto p-[15px] rounded-[60px] w-full">
                            <Text
                                className="absolute leading-[116.70%] left-[7%] sm:text-[31px] md:text-[33px] text-[35px] text-black-900 mt-[20px] w-[73%] sm:w-full"
                                size="txtInterMedium35"
                            >
                                Lee y resuelve
                            </Text>
                            <Img
                                className="absolute bottom-[15%] h-[249px] w-[89%]"
                                src="images/img_capturadepantalla_164x474.png"
                                alt="capturadepantal_Four"
                            />
                        </div>
                        <Text
                        className="bg-deep_orange_A200 h-[66px] justify-center ml-auto mr-[27px] mt-[-33px] pb-[9px] pt-[13px] sm:px-5 px-[35px] rounded-[33px] sm:text-[31px] md:text-[33px] text-[35px] text-center text-white_A700 w-[292px] z-[1]"
                        size="txtInterBlack35"
                        >
                        INICIAR
                        </Text>
                    </div>
                    <div className="flex flex-col relative w-full">
                        <div className=" md:h-[251px] h-[435px] mx-auto w-full">
                            <div className="flex flex-col gap-[13px] h-full items-center justify-start mt-[15px] mx-auto w-[92%]">
                                <Text
                                className="leading-[116.70%] sm:text-[31px] md:text-[33px] text-[35px] text-black-900 mt-[20px] w-[93%] sm:w-full"
                                size="txtInterMedium35"
                                >
                                    Calcula el resultado correcto
                                </Text>
                                <Img
                                className="absolute bottom-[15%] h-[234px] md:h-auto object-cover w-full"
                                src="images/img_capturadepantalla_266x468.png"
                                alt="capturadepantal"
                                />
                            </div>
                            <div className="absolute bg-deep_orange_A200_7f border-[6px] border-deep_orange_A200 border-solid flex flex-col h-full inset-[0] items-center justify-center m-auto py-[49px] rounded-[60px] w-full">
                                <div className="bg-deep_orange_A200 flex sm:flex-col flex-row gap-1.5 items-center justify-start mt-[268px] p-0.5 w-full">
                                <Text
                                    className="sm:ml-[0] ml-[33px] text-3xl sm:text-[26px] md:text-[18px] text-center text-white_A700"
                                    size="txtInterBlack30WhiteA700"
                                >
                                    PRACTICA COMPLETA
                                </Text>
                                <Img
                                    className="sm:flex-1 h-[59px] md:h-auto mb-1.5 object-cover w-[13%] sm:w-full"
                                    src="images/img_image1017.png"
                                    alt="image1017"
                                />
                                </div>
                            </div>
                            <Text
                                className="absolute bg-deep_orange_A200 h-[66px] justify-center ml-auto left-[30%] bottom-[-7%] pb-[9px] pt-[13px] sm:px-5 px-[35px] rounded-[33px] sm:text-[31px] md:text-[33px] text-[35px] text-center text-white_A700 w-[292px]"
                                size="txtInterBlack35"
                                >
                                REPETIR
                            </Text>
                        </div>
                    </div>
                </div>
                <Text
                    className="ml-5 md:ml-[0] ml-[85px] mt-[55px] text-deep_orange_A200"
                    as="h3"
                    variant="h3"
                >
                    4.{""}Juegos
                </Text>
                <div className="gap-[29px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 items-center justify-between ml-1 md:ml-[0] mt-[35px] w-full">
                    <div className="flex flex-col relative w-full">
                        <div className="border-[6px] border-deep_orange_A200 border-solid md:h-[249px] h-[435px] mx-auto p-[15px] rounded-[60px] w-full">
                            <Text
                                className="absolute leading-[116.70%] left-[7%] sm:text-[31px] md:text-[33px] text-[35px] text-black-900 mt-[20px] w-[73%] sm:w-full"
                                size="txtInterMedium35"
                            >
                                Unir con flechas las fracciones
                            </Text>
                            <Img
                                className="absolute bottom-[15%] h-[249px] left-[8%] object-cover w-[79%]"
                                src="images/img_capturadepantalla_255x464.png"
                                alt="capturadepantal_Three"
                            />
                        </div>
                        <Text
                            className="bg-deep_orange_A200 h-[66px] justify-center ml-auto mr-[27px] mt-[-33px] pb-[9px] pt-[13px] sm:px-5 px-[35px] rounded-[33px] sm:text-[31px] md:text-[33px] text-[35px] text-center text-white_A700 w-[292px] z-[1]"
                            size="txtInterBlack35"
                        >
                            INICIAR
                        </Text>
                    </div>
                    <div className="flex flex-col relative w-full">
                    <div className="border-[6px] border-deep_orange_A200 md:h-[249px] h-[435px] border-solid flex flex-col gap-[22px] items-center justify-start mx-auto p-[15px] rounded-[60px] w-full">
                            <Text
                                className="absolute leading-[116.70%] left-[7%] sm:text-[31px] md:text-[33px] text-[35px] text-black-900 mt-[20px] w-[73%] sm:w-full"
                                size="txtInterMedium35"
                            >
                                Unir con flechas las fracciones
                            </Text>
                            <Img
                                className="absolute bottom-[15%] h-[249px] left-[8%] object-cover w-[79%]"
                                src="images/img_capturadepantalla_255x460.png"
                                alt="capturadepantal_Four"
                            />
                        </div>
                        <Text
                        className="bg-deep_orange_A200 h-[66px] justify-center ml-auto mr-[27px] mt-[-33px] pb-[9px] pt-[13px] sm:px-5 px-[35px] rounded-[33px] sm:text-[31px] md:text-[33px] text-[35px] text-center text-white_A700 w-[292px] z-[1]"
                        size="txtInterBlack35"
                        >
                        INICIAR
                        </Text>
                    </div>
                    <div className="flex flex-col relative w-full">
                        <div className="border-[6px] border-deep_orange_A200 md:h-[249px] h-[435px] border-solid flex flex-col gap-[22px] items-center justify-start mx-auto p-[15px] rounded-[60px] w-full">
                            <Text
                                className="absolute leading-[116.70%] left-[7%] sm:text-[31px] md:text-[33px] text-[35px] text-black-900 mt-[20px] w-[73%] sm:w-full"
                                size="txtInterMedium35"
                            >
                                Selecciona el resultado correcto
                            </Text>
                            <Img
                                className="absolute bottom-[15%] h-[249px] left-[8%] object-cover w-[79%]"
                                src="images/img_capturadepantalla_266x468.png"
                                alt="capturadepantal_Four"
                            />
                        </div>
                        <Text
                            className="bg-deep_orange_A200 h-[66px] justify-center ml-auto mr-[27px] mt-[-33px] pb-[9px] pt-[13px] sm:px-5 px-[35px] rounded-[33px] sm:text-[31px] md:text-[33px] text-[35px] text-center text-white_A700 w-[292px] z-[1]"
                            size="txtInterBlack35"
                        >
                            INICIAR
                        </Text>
                    </div>
                </div>
            </div>
          <div className="flex flex-col font-opensans items-center justify-start max-w-[1420px] mt-[124px] mx-auto md:px-5 w-full">
            <Footer/>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

export default FraccionesPage;