import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import ProfileScreen from "pages/Profile/ProfileScreen";
import TestScreen from "pages/Tests/TestScreen";
import FraccionesPage from "pages/Fracciones/FraccionesPage";
const TEST = React.lazy(() => import("pages/TEST"));
const APRENDUXMATEMATICA = React.lazy(() => import("pages/APRENDUXMATEMATICA"));
const SOBRENOSOTROS = React.lazy(() => import("pages/SOBRENOSOTROS"));
const PLANDEESTUDIOS = React.lazy(() => import("pages/PLANDEESTUDIOS"));
const INICIO = React.lazy(() => import("pages/INICIO"));
const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router>
        <Routes>
          <Route path="/" element={<INICIO />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/plandeestudios" element={<PLANDEESTUDIOS />} />
          <Route path="/sobrenosotros" element={<SOBRENOSOTROS />} />
          <Route path="/aprenduxmatematica" element={<APRENDUXMATEMATICA />} />
          <Route path="/test" element={<TEST />} />
          <Route path="/profile" element={<ProfileScreen />} />
          <Route path="/fracciones" element={<FraccionesPage />} />
          <Route path="/tests" element={<TestScreen />} />
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
