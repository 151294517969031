import React, {useContext} from "react";
import { UserContext } from "../../providers/userProvider";
import { useNavigate } from "react-router-dom";

import { Button, Img, Text } from "components";
import { Box } from "@mui/material";

const MenuStart = (props) => {
  const navigate = useNavigate();
  const { user, logout } = useContext(UserContext);
  console.log("user", user);

  return (
    <>
    <Box padding={2}>
      <div className={props.className}>
        <Img
          src={props?.logoImage}
          className="h-[141px] sm:h-auto object-cover w-[35%] md:w-full"
          alt="logolargoaprend"
        />
          <Text
            className="common-pointer font-inter font-normal md:ml-[0] ml-[34px] text-black_900"
            variant="body1"
            onClick={() => navigate("/")}
          >
            {props?.homeTab}
          </Text>
          <Text
            className="common-pointer font-inter font-normal md:ml-[0] ml-[34px] text-black_900"
            variant="body1"
            onClick={() => navigate("/sobrenosotros")}
          >
            {props?.aboutUsTab}
          </Text>
          <Text
            className="common-pointer font-inter font-normal md:ml-[0] ml-[34px] text-black_900"
            variant="body1"
            onClick={() => navigate("/plandeestudios")}
          >
            {props?.studyPlanTab}
          </Text>
        {!user && (
          <>
          <Button
            className="common-pointer cursor-pointer font-bold font-inter min-w-[190px] ml-[34px] md:ml-[0] md:mt-0 text-3xl sm:text-[26px] md:text-[28px] text-center text-white_A700"
            onClick={() => navigate("/test")}
            shape="RoundedBorder39"
            size="md"
            variant="FillDeeporangeA200"
          >
            {props?.loginButtonTab}
          </Button>
          </>
        )}
        {user && (
          <>
          <Text
            className="common-pointer font-inter font-normal md:ml-[0] ml-[24px] text-black_900"
            variant="body1"
            onClick={() => {
              logout();
              navigate("/");
            }}
          >
            Salir
          </Text>
          <Button
            className="common-pointer cursor-pointer font-bold font-inter ml-[34px] min-w-[190px] md:ml-[0] md:mt-0 text-3xl sm:text-[26px] md:text-[28px] text-center text-white_A700"
            onClick={() => navigate("/profile")}
            shape="RoundedBorder39"
            size="md"
            variant="FillDeeporangeA200"
          >
            {user.first_name}
          </Button>
          </>
        )}
      </div>
    </Box>
    </>
  );
};

MenuStart.defaultProps = {
  logoImage: "images/img_logolargoaprendux04.png",
  homeTab: "Inicio",
  aboutUsTab: "Sobre nosotros",
  studyPlanTab: "Plan de estudios",
  loginButtonTab: "Iniciar Sesión",
};

export default MenuStart;
