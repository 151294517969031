import React, {useContext, useEffect} from "react";
import { UserContext } from "../../providers/userProvider";
import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { Button, Img, Input, List, Text } from "components";
import Footer from "components/Footer";
import { getTest } from "../../service/testService";
import MenuStart from "components/MenuStart";
import { CircularProgress } from "@mui/material";


const ProfileScreen = () => {
  const navigate = useNavigate();
  const { user, fetchUser } = useContext(UserContext);
  const [tests, setTests] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    const getTests = async () => {
        setIsLoading(true);
        await fetchUser(user.id).then((new_user) => {
            if (new_user.tests.length === 0) {
                setIsLoading(false);
                return;
            }
            let contador = 0;
            var new_tests = tests.slice();
            new_user.tests.forEach(async test_id => {
                await getTest(test_id).then((response) => {
                    console.log("response getTEsts", response);
                    contador ++;
                    new_tests.push(response);
                    if(new_user.tests.length === contador) {
                        console.log("entro aca");
                        setTests(new_tests);
                        setIsLoading(false);
                    }
                })
            });
        });
    };
    if(user) {
        getTests();
    }
  }, []);

  const handleTest = (test_id) => {
    navigate(`/tests`, { state: { test_id } });
  };

  return (
    <>
        <div className="bg-white_A700 flex flex-col font-inter items-center justify-start mx-auto py-[38px] w-full">
            <div className="flex flex-col items-center justify-start mb-20 w-full">
                <MenuStart
                    className="flex flex-row items-center ml-[10px] mr-[10px] w-full"
                />
                {user && (
                    <div className="flex flex-col justify-start mt-[62px] md:px-5 w-[94%] md:w-full">
                        <div className="flex md:flex-col flex-row md:gap-5 items-start justify-start mr-[42px] w-[98%] md:w-full">
                        <Text
                            className="font-black md:ml-[0] ml-[7px] text-deep_orange_A200"
                            as="h1"
                            variant="h1"
                        >
                            ¡Hola {user.first_name}!
                        </Text>
                        </div>
                    </div>
                )}
                {isLoading && (
                    <div
                     className="flex flex-col items-end p-[38px] ml-[45px] sm:px-5 rounded-[45px]"
                    >
                        <CircularProgress color="inherit"/> 
                    </div>
                )}
                {!isLoading && tests.length === 0 && (
                    <div className="flex flex-col justify-start mt-[62px] md:px-5 w-[94%] md:w-full">
                        <div className="flex md:flex-col flex-row md:gap-5 items-start justify-start mr-[42px] w-[98%] md:w-full">
                            <Text
                                className=" font-normal sm:ml-[0] text-black_900"
                                as="body1"
                                variant="body1"
                            >
                                No hay test pendientes.
                            </Text>
                        </div>
                    </div>
                )}
                {!isLoading && tests.length > 0 && (
                <div className="flex flex-col justify-start mt-[62px] md:px-5 w-[94%] md:w-full">
                    <div className="flex md:flex-col flex-row md:gap-5 items-start justify-start mr-[42px] w-[98%] md:w-full">
                    <Text
                        className=" font-normal sm:ml-[0] text-black_900"
                        as="body1"
                        variant="body1"
                    >
                        A continuación se encuentran tus tests pendientes:
                    </Text>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                    >
                    {tests.map((test) => (
                        <div
                        className="common-pointer bg-deep_orange_A200 flex flex-col p-[38px] sm:px-5 rounded-[69px] mt-[64px] w-[80%] md:w-full"
                        onClick={() => handleTest(test.id)}
                        >
                        <Text
                            className="font-black text-center text-white_A700"
                            as="h4"
                            variant="h4"
                        >
                            {test.name}
                        </Text>
                        </div>
                    ))}
                    </Grid>
                    </div>
                </div>
                )}
                <div className="flex flex-col font-opensans items-center justify-start max-w-[1420px] mt-[124px] mx-auto md:px-5 w-full">
                    <Footer/>
                </div>
            </div>
        </div>
    </>
  );
};

export default ProfileScreen;
