import React, { useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../providers/userProvider";
import { Text } from "components";
import Footer from "components/Footer";
import MenuStart from "components/MenuStart";
import { createAnswer, getAllQuestions } from "../../service/testService";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Box, Stack } from "@mui/material";
import { CircularProgress } from "@mui/material";
import Swal from 'sweetalert2';
import { updateUser } from "service/userService";

const TestScreen = (props) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { state } = useLocation();
  const { test_id } = state;
  const [test, setTest] = React.useState({});
  const [questions, setQuestions] = React.useState([]);
  const [answers, setAnswers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [values, setValues] = React.useState([]);

  useEffect(() => {
    const getTestData = async () => {
      setIsLoading(true);
      getAllQuestions(test_id).then((response) => {
        console.log("reponse", response);
        setTest(response);
        console.log("response.questions", response.questions);
        setQuestions(response.questions);
        setIsLoading(false);
      });
    };
    if (test_id){
        getTestData();
    }
  }, []);

  const handleChange = (event, q, idx) => {
    const newValues = values.slice()
    newValues[idx] = event.target.value
    setValues(newValues);
    const new_answers = answers.slice();
    console.log("answers antes", answers)
    new_answers[idx] = {
      user_id: user.id,
      question_id: q.id,
      answer: event.target.value
    };
    console.log("new_answers", new_answers)
    setAnswers(new_answers);

  };

  const testDone = () => {
    var new_tests = user.tests.filter(m => {
      return m !== test_id;
    });
    var new_tests_completed = user.tests_completed
    new_tests_completed.push(test_id);
    updateUser({ 
      id: user.id, 
      tests: new_tests, 
      tests_completed: new_tests_completed, 
      category: user.category
    }).then((response) => {
      console.log("updateuser response", response);
      setSubmitLoading(false);
      Swal.fire({
        title: '¡Éxito!',
        text: 'Se ha completado el test exitosamente',
        icon: 'success',
        confirmButtonColor: 'green',
      }).then(function() {
        navigate("/profile");
      });
    }).catch((error) => {
      setSubmitLoading(false);
      console.log("error", error);
      Swal.fire({
        title: '¡Error!',
        text: 'Ocurrió un error. Por favor intenta mas tarde',
        icon: 'error',
        confirmButtonColor: 'red',
      });
    });
  }

  const handleSubmit = () => {
    setSubmitLoading(true);
    const len_questions = questions.length;
    const len_answers = answers.length;
    console.log("len_questions", len_questions);
    console.log("len_answers", len_answers);
    console.log("answers", answers);
    if(len_questions === len_answers){
      let contador = 0;
      answers.forEach((answer) => {
        createAnswer(answer).then((response) => {
          contador++;
          console.log("response", response);
          if (contador === len_answers){
            console.log("entro a testDone");
            testDone();
          }
        }).catch((error) => {
          setSubmitLoading(false);
          console.log("error", error);
          Swal.fire({
            title: '¡Error!',
            text: 'Ocurrió un error. Por favor intenta mas tarde',
            icon: 'error',
            confirmButtonColor: 'red',
          });
        });
      });
      console.log("answers", answers);
    } else {
      setSubmitLoading(false);
      Swal.fire({
        title: '¡Error!',
        text: 'Por favor responda todas las preguntas',
        icon: 'error',
        confirmButtonColor: 'red',
      });
    }
  };

  return (
    <>
    <div className="bg-white_A700 flex flex-col font-inter py-[38px] w-full">
        <div className="flex flex-col items-center justify-start mb-1 w-full">
          <MenuStart
            className="flex flex-row items-center ml-[10px] mr-[10px] w-full"
          />
          {isLoading && (
              <div
                className="flex flex-col items-end p-[38px] ml-[45px] sm:px-5 rounded-[45px]"
              >
                  <CircularProgress color="inherit"/> 
              </div>
          )}
          {!isLoading && test && questions.length > 0 &&(
            <>
            <Box sx={{backgroundColor: "#e86e39", width: "100%", textAlign:"center", padding: "100px"}}>
              <Text
                className="text-white_A700"
                as="h3"
                variant="h3"
              >
                ¡ESTAS LISTO/A PARA COMPLETAR ESTE TEST!
              </Text>
              <Text
                className="font-normal text-white_A700"
                variant="body1"
              >
                {test.name}
              </Text>
            </Box>
            <div className="flex md:flex-col flex-row md:gap-5 items-start justify-start ml-[42px] mt-[45px] w-[98%] md:w-full">
                <Text
                    className=" font-normal sm:ml-[0] text-black_900"
                    as="body1"
                    variant="body1"
                >
                  {test.instructions}
                </Text>
            </div>
            <Stack spacing={4} sx={{paddingTop: "42px", paddingLeft: 20, paddingRight: 20}}>
              {questions.map((q, index) => (
                <>
                <FormControl sx={{justifyContent:"flex-start"}}>
                  <FormLabel id="demo-controlled-radio-buttons-group">{index+1}. {questions[index].question}</FormLabel>
                  {questions[index].image !== "" && (
                    <div >
                      <img src={questions[index].image} alt="imagen" sx={{paddingTop: 10}}/>
                    </div>
                  )}
                  <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={values[index]}
                      onChange={(event) => handleChange(event, q, index)}
                  >
                    {q.options.map((option) => (
                      <FormControlLabel value={option} control={<Radio />} label={option} />
                    ))}
                  </RadioGroup>
                </FormControl>
                </>
              ))}
            </Stack>
            {!submitLoading && (
              <div
                className="common-pointer bg-deep_orange_A200 flex flex-col p-[18px] sm:px-5 rounded-[69px] mt-[64px] w-[30%] md:w-full"
                onClick={() => handleSubmit()}
                >
                <Text
                    className="font-black text-center text-white_A700"
                    as="h6"
                    variant="h6"
                >
                    Terminar
                </Text>
              </div>
            )}
            {submitLoading && (
              <div
                className="bg-deep_orange_A200 flex flex-col p-[18px] sm:px-5 rounded-[69px] mt-[64px] w-[30%] md:w-full"
              >
                <CircularProgress color="inherit"/> 
              </div>
            )}
            <div className="flex flex-col items-center font-opensans items-center justify-start max-w-[1420px] mt-[124px] mx-auto md:px-5 w-full">
              <Footer/>
            </div>
            </>
          )}
          </div> 
        </div>
    </>
  );
};

export default TestScreen;