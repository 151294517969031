import axios from "./axios";

export async function createUser({
    first_name,
    last_name,
    email,
    password,
    age,
    gender,
    institution,
    degree,
    management,
    shift,
    family_bond_1,
    name_1,
    age_1,
    work_1,
    family_bond_2,
    name_2,
    age_2,
    work_2,
    tests,
    tests_completed,
}) {
  const body = {
    first_name,
    last_name,
    email,
    password,
    age,
    gender,
    institution,
    degree,
    management,
    shift,
    family_bond_1,
    name_1,
    age_1,
    work_1,
    family_bond_2,
    name_2,
    age_2,
    work_2,
    tests,
    tests_completed,
  };
  console.log("Body", body);
  const response = await axios.post(`/users`, body);

  return response.data;
}

export async function getUser(id) {
    const response = await axios.get(`/users/${id}`);
    return response.data;
}

export async function getUserLogged({email, password}) {
    const response = await axios.get(`/users/${email}/${password}`);
    return response.data;
}

export async function updateUser({category, tests, tests_completed, id}) {
  const body = {
    category,
    tests,
    tests_completed,
  };
  const response = await axios.put(`/users/${id}`, body);
  return response.data;
}