import React from "react";

import { Img, Line, Text } from "components";

const Footer = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="flex md:flex-col flex-row md:gap-5 items-start justify-start w-full">
          <div className="flex md:flex-1 flex-col items-center justify-start w-[46%] md:w-full">
            <div className="flex flex-col items-center justify-start w-[81%] md:w-full">
              <Img
                src={props?.logoImage}
                className="h-[121px] md:h-auto object-cover w-full"
                alt="logolargoaprend_One"
              />
            </div>
            <Text
              className="font-opensans leading-[160.00%] ml-8 md:ml-[0] text-black_900 w-[96%] sm:w-full"
              variant="body3"
            >
              {props?.copyrightText}
            </Text>
          </div>
          <Line className="bg-gray_400 h-[253px] md:h-px ml-11 md:ml-[0] md:mt-0 mt-3.5 md:w-full w-px" />
          <div className="flex flex-col gap-[13px] items-start justify-start mb-[3px] ml-24 md:ml-[0] md:mt-0 mt-1.5 w-[15%]  md:w-full">
            <Text
              className="font-jost font-medium text-black_900"
              variant="body1"
            >
              {props?.userMenu}
            </Text>
            <Text
              className="font-opensans leading-[198.50%] text-black_900"
              variant="body3"
            >
              {props?.menuTabs}
            </Text>
          </div>
          <div className="h-[178px] md:h-[184px] ml-40 md:ml-[0] md:mt-0 mt-[5px] relative w-[10%] md:w-full">
            <div className="flex m-auto w-full">
              <div className="flex flex-col gap-5 items-start justify-start my-auto w-[220px]">
                <Text
                  className="font-jost font-medium text-black_900 w-auto"
                  variant="body1"
                >
                  {props?.userPlatforms}
                </Text>
                <Text
                  className="font-opensans leading-[198.50%] text-gray_800"
                  variant="body3"
                >
                  {props?.platformsTabs}
                </Text>
              </div>
            </div>
            <div className="absolute bottom-[6%] flex flex-col gap-5 justify-start right-[2%]">
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Footer.defaultProps = {
  logoImage: "images/img_logolargoaprendux04.png",
  companyDescription:
    "Lorem ipsum dolor sitonsecteturadipisicing elit sed do eiusmod temporincididunt Laoreet non sagittis aliquam bibendum.",
  socialNetworks: "images/img_follow.svg",
  userMenu: "Menu",
  menuTabs: (
    <>
      Inicio
      <br />
      Sobre Nosotros
      <br />
      Plan de Estudios
      <br />
      Iniciar sesión
    </>
  ),
  userPlatforms: "Plataformas",
  platformsTabs: (
    <>
      Matemática
      <br />
      Lengua
      <br />
      Inglés
    </>
  ),
  proximamenteText: "Proximamente",
  proximamenteText2: "Proximamente",
  copyrightText: "Copyright © APRENDUX | Designed by APRENDUX",
};

export default Footer;
