import React from "react";
import PropTypes from "prop-types";

const shapes = {
  RoundedBorder39: "rounded-[39px]",
  RoundedBorder36: "rounded-[36px]",
};
const variants = {
  FillDeeporangeA200: "bg-deep_orange_A200 text-white_A700",
  FillLightblue70001: "bg-light_blue_700_01 text-white_A700",
  FillBlack: "bg-black text-white_A700",
};
const sizes = { sm: "p-[18px]", md: "p-[21px] sm:px-5" };

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant,
  size,
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${(shape && shapes[shape]) || ""} ${
        (size && sizes[size]) || ""
      } ${(variant && variants[variant]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shape: PropTypes.oneOf(["RoundedBorder39", "RoundedBorder36"]),
  variant: PropTypes.oneOf(["FillDeeporangeA200", "FillLightblue70001"]),
  size: PropTypes.oneOf(["sm", "md"]),
};

Button.defaultProps = { className: "", shape: "", variant: "", size: "" };
export { Button };
